const pulsewireSettings = {
	title: "SnapScope",
	description: "Designed for younger audiences, SnapScope delivers bite-sized, visually engaging news with an emphasis on social trends, tech, and global affairs. It features 60-second video summaries, gamified news challenges, and personalized content feeds, making news fun and interactive.",
	logo: "/logo.png",
	logoBig: "/logo512.png",
	headerBg: "white",
	footerBg: "white",
	headerTextColor: "#000",
	footerTextColor: "#000",
	style: 'critical'
};

export default pulsewireSettings;
